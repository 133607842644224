import axios from "axios";

export default class ValheimApi {
    #baseUrl;

    constructor() {
        this.#baseUrl = (window.location.hostname === 'localhost' ? 'http://localhost:5000' : '/api');
    }

    upload(file) {
        let formData = new FormData();
        formData.append('dll', file);
        return axios.post(`${this.#baseUrl}/upload`, formData, {
            validateStatus: false,
            headers: {'Content-Type': 'multipart/form-data'},
        });
    }

    check(sha256) {
        return axios.get(`${this.#baseUrl}/check/${sha256}`, {
            validateStatus: false,
        });
    }

    downloadLink(sha256, mDataPerSec) {
        return `${this.#baseUrl}/download/${sha256}?m_data_per_sec=${mDataPerSec}`;
    }
}
