export default class StringUtils {
    hexEncode = function (s) {
        let hex, i;

        let ret = '';
        for (i = 0; i < s.length; i++) {
            hex = s.charCodeAt(i).toString(16);
            ret += ('' + hex).slice(-4);
        }

        return ret
    }

}