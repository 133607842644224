import React from 'react';
import ValheimApi from "./api";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CryptoJS from 'crypto-js'
import StringUtils from "./utils/StringUtils";
import MonoSpace from "./components/MonoSpace";
import {css} from 'glamor';

css.global('dt', {fontWeight: 'bold'});
css.global('dd', {paddingBottom: '15px'});


class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sha256: null,
            status: null,
            m_dataPerSec: null,
            new_m_dataPerSec: null,
        };
        this.api = new ValheimApi();
        this.stringUtils = new StringUtils();
    }

    arrayBufferToWordArray(s) {
        let r = [];
        for (let i = 0; i < s.length; i += 4) {
            // eslint-disable-next-line no-mixed-operators
            r.push(s.charCodeAt(i) << 24 | s.charCodeAt(i + 1) << 16 | s.charCodeAt(i + 2) << 8 | s.charCodeAt(i + 3));
        }
        return CryptoJS.lib.WordArray.create(r, s.length);
    }

    updateStateByResponse(sha256, response) {
        if (response.data.hasOwnProperty('error')) {
            toast(response.data.error);
            return;
        }
        if (response.data.hasOwnProperty('sha256') && (response.data.sha256 !== sha256)) {
            toast('SHA256 mismatch');
            return;
        }
        if (response.status !== 200) {
            toast(`Invalid status code: ${response.status}.`)
            return;
        }

        if (response.data.m_dataPerSec === null) {
            toast('Cannot read m_dataPerSec, you probably did not upload assembly_valheim.dll')
            this.setState({
                status: 'done',
                m_dataPerSec: 'invalid file'
            });
        } else {
            this.setState({
                status: response.data.status,
                m_dataPerSec: response.data.m_dataPerSec,
                new_m_dataPerSec: response.data.m_dataPerSec,
            });
        }
    }

    render() {
        return (
            <div className="App">
                <h1>ValPatch</h1>
                <h2>Steps</h2>
                <ol>
                    <li>
                        Right-click Valheim and select <MonoSpace>Properties...</MonoSpace><br/>
                        <img src={process.env.PUBLIC_URL + '/step1.png'} alt="step 1" height={228}/>
                    </li>
                    <li>
                        Under "Local Files" click "Browse..."<br/>
                        <img src={process.env.PUBLIC_URL + '/step2.png'} alt="step 2" height={228}/>
                    </li>
                    <li>
                        Find the file <MonoSpace>valheim_data/Managed/assembly_valheim.dll</MonoSpace> take note of the
                        path upload it in the form below.
                    </li>
                    <li>
                        The patcher will extract the current value of <MonoSpace>m_dataPerSec</MonoSpace> and display
                        it. Adapt it to your needs.
                    </li>
                    <li>Click Download and overwrite your assembly_valheim.dll</li>
                </ol>

                <h2>Patcher</h2>
                <input type="file" name="file" onChange={event => {
                    let reader = new FileReader();
                    const file = event.target.files[0];
                    if (! file) return;
                    reader.onload = event => {
                        let wordArray = this.arrayBufferToWordArray(event.target.result);
                        let sha256 = CryptoJS.SHA256(wordArray).toString();
                        this.setState({sha256: sha256}, () => {
                            this.api.check(sha256).then(response => {
                                switch (response.status) {
                                    case 200:
                                        this.updateStateByResponse(sha256, response);
                                        break;
                                    case 404:
                                        this.setState({
                                            status: 'uploading...'
                                        }, () => {
                                            this.api.upload(file).then(response => {
                                                this.updateStateByResponse(sha256, response);
                                            }).catch(response => {
                                                if (! response.status) {
                                                    toast('Network Error, the Patching API is probably down.');
                                                    this.setState({status: 'Service down'});
                                                } else {
                                                    toast(response.data.error);
                                                }
                                            });
                                        });
                                        break
                                    default:
                                        toast(response.data.error)
                                }
                            });
                        });
                    };
                    reader.onerror = () => {
                        toast('Cannot read file :shrug:');
                    }
                    reader.readAsBinaryString(file);
                }}/>
                <ul>
                    <li>SHA256: {this.state.sha256 === null ? 'unknown' : this.state.sha256}</li>
                    <li>Status: {this.state.status === null ? 'unknown' : this.state.status}</li>
                    <li>m_dataPerSec: {this.state.m_dataPerSec === null ? 'unknown' : this.state.m_dataPerSec}</li>
                </ul>
                {this.state.new_m_dataPerSec !== null ? <form>
                    <input type="number" value={this.state.new_m_dataPerSec} onChange={(event) => {
                        this.setState({new_m_dataPerSec: event.target.value});
                    }}/>
                    <button onClick={event => {
                        this.setState({new_m_dataPerSec: this.state.new_m_dataPerSec * 2});
                        event.preventDefault();
                    }}>
                        Double
                    </button>
                    <a href={this.api.downloadLink(this.state.sha256, this.state.new_m_dataPerSec)}>Download</a>
                </form> : ''}

                <h2>FAQ</h2>
                <dl>
                    <dt>
                        I overwrote my <MonoSpace>assembly_valheim.dll</MonoSpace> and now my game doesn't work anymore.
                    </dt>
                    <dd>Tough luck.</dd>

                    <dt>But I want to play my game again!</dt>
                    <dd>There is a "Verify integrity of game files..." button, click it.</dd>

                    <dt>I'm going to hold you responsible, already informed authorities and my lawyer.</dt>
                    <dd>Good luck.</dd>

                    <dt>Should I trust page like this?</dt>
                    <dd>No.</dd>

                    <dt>What is the problem with a page like this?</dt>
                    <dd>
                        This pages modifies executables you are uploading. Apart from the advertised changes, this page
                        may also include malware in those files. I am not planing to do this on purpose. But who am I?
                        Do you even know me? I'm just a random person from the internet.
                    </dd>
                </dl>
                <br/><br/><br/><br/><br/><br/>
                <br/><br/><br/><br/><br/><br/>
                <br/><br/><br/><br/><br/><br/>
                <ToastContainer/>
            </div>
        );
    }

}

export default App;
